!function (library) {
    // Calls the second IIFE and locally passes in the global jQuery, window, and document objects
    library(window, document, window.jQuery);
}

// Locally scoped parameters 
(function (window, document, $) {

    var $body   = $('body')
    
    var locale  = $body.data('locale');
    var pt_code = 620;

    // The DOM is ready!
    $(initPage);
    function initPage(){

        // Selectize 
        
        $('.selectized').selectize({
            plugins: ['remove_button'],
            openOnFocus: false
        });

        $('.selectized-tags').selectize({
            plugins: ['remove_button'],
            delimiter: ',',
            persist: false,
            openOnFocus: false,
            maxItems: 15,
            create: function(input) {
                return {
                    value: input,
                    text: input
                }
            },
            render: {
                option_create: function(data, escape) {
                    return '<div class="create">' + this.$input.data('create-label') + ' <strong>' + escape(data.input) + '</strong>&hellip;</div>';
                }
            },
            onDropdownOpen: function() {
              this.close();
            }
        });


        // Tooltip

        $('[data-toggle="tooltip"]').tooltip();


        // Datetime Picker

        moment.locale('pt', {
            weekdaysMin : ["D", "S", "T", "Q", "Q", "S", "S"]
        });

        $('.datepicker').datetimepicker({
            locale: locale,
            format: 'DD-MM-YYYY',
            // useCurrent: false,
            // minDate: moment().add(1, 'd').millisecond(0).second(0).minute(0).hour(0),
            icons: {
                previous: 'fa fa-chevron-left',
                next: 'fa fa-chevron-right'
            }
        });


        // Button group checked initializaton 
        
        if ($('.btn-group').length) {
            $('[checked="checked"]').parent().click();
        };


        // Modals
        $('*[data-show]').modal('show');

        // $('input:radio[data-toggle="modal"]').click(function(){
        //     $(this).modal('show');
        // });


        // Handle region list on #form-ad
        $('#form-ad').length && regionListHandler();

        // Handle reply link on #form-ad
        $('#form-ad').length && adReplyHandler();

        // Handle email alert on #form-ad
        $('#form-ad').length && adAlertEmailHandler();

        // Equalize feature employers height
        $('.featured-employer').length && $('.featured-employer').matchHeight({
            byRow: false
        });


        // if (getParameterByName('parent_job')) {
        //     fillChildJobs(getParameterByName('parent_job'));
        // }

    }


    // The rest of your code goes here! ---------------------------------------


    /*
    |--------------------------------------------------------------------------
    | EVENTS
    |--------------------------------------------------------------------------
    */

    $("#parent_job").change(function() {
        var resource        = '/ads/filter/child/ajax';
        var parent_job      = $(this);
        var child_job       = $('#child_job');   

        child_job.empty();
        child_job.append('<option>A carregar...</option>');

        $.get(resource,
            {
                parent_job : parent_job.val()
            },
            function(data)
            {
                child_job.empty();
                $.each(data, function(index, value)
                {
                    if (index != "") {
                        child_job.append($("<option />").val(index).text(value));
                    } else {
                        child_job.prepend($("<option />").val(index).text(value));
                    }

                });
            }
        );
    });

    $('.add-ad-favorites').click(function(e) {
        e.preventDefault();
        var add_id = $(this).data("ad");
        $('.add-ad-favorites').hide();
        $('.remove-ad-favorites').show();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            url:  '/ads/favorites/add',
            type: 'GET',
            data: {id : add_id},
            success: function(data){
            }
        });
    });

    $('.remove-ad-favorites').click(function(e) {
        e.preventDefault();
        var add_id = $(this).data("ad");
        $('.remove-ad-favorites').hide();
        $('.add-ad-favorites').show();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            url:  '/ads/favorites/remove',
            type: 'GET',
            data: {id : add_id},
            success: function(data){
            }
        });
    });

    $('.save-candidate').click(function(e) {
        e.preventDefault();
        var candidate_id = $(this).data("candidate");

        $('.modal-talent-pool').modal('show');
    });

    // $('.submit-talent-pool').click(function(e) {
    //     var candidate_id = $('#candidate_id').val();
    //     var ads = [];

    //     $('.add-candidate-favorites').hide();
    //     $('.remove-candidate-favorites').show();
        
    //     $('input[name=ad_id]:checked').each(function() {
    //         ads.push($(this).val());
    //     });
        
    //     $.ajaxSetup({
    //         headers: {
    //             'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    //         }
    //     });

    //     $.ajax({
    //         url:  '/candidates/favorites/add',
    //         type: 'GET',
    //         data: {id : candidate_id, ads : ads},
    //         success: function(data){
    //             $('.modal-talent-pool').modal('hide');
    //         }
    //     });
    // });

    $('.add-candidate-favorites-ad').click(function(e) {
        e.preventDefault();

        $('.modal-talent-pool-ad').modal('show');
    });

    $('.submit-candidate-favorites-ad').click(function(e) {
        e.preventDefault();

        var ad_id = $('input[name=ad_id]').val();
        var candidates = [];

        $('input[name=user_id]:checked').each(function(){
            candidates.push($(this).val());
        });

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            url:  '/ads-candidates/favorites/add',
            type: 'GET',
            data: {id : ad_id, candidates : candidates},
            success: function(data){
                $('.modal-talent-pool-ad').modal('hide');
                location.reload();
            }
        });
    });


    $('.remove-candidate-favorites').click(function(e) {
        e.preventDefault();
        var candidate_id = $(this).data("candidate");
        $('.remove-candidate-favorites').hide();
        $('.add-candidate-favorites').show();

        $.ajaxSetup({
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            }
        });

        $.ajax({
            url:  '/candidates/favorites/remove',
            type: 'GET',
            data: {id : candidate_id},
            success: function(data){
                
            }
        });
    });


    $('.btn-file :file').on('change', function() {
        var input = $(this),
        numFiles = input.get(0).files ? input.get(0).files.length : 1,
        label = input.val().replace(/\\/g, '/').replace(/.*\//, '');
        input.trigger('fileselect', [numFiles, label]);
    }); 


    $('.btn-file :file').on('fileselect', function(event, numFiles, label) {
        
        var input = $(this).parents('.input-group').find(':text'),
            log = numFiles > 1 ? numFiles + ' files selected' : label;
        
        if( input.length ) {
            input.val(log);
        } else {
            if( log ) alert(log);
        }
    });


    $('a[data-confirm]').on('click', function(e) {
        
        e.preventDefault();

        var message = $(this).data('confirm');
        var url     = $(this).attr('href');

        // Show dialogue
        bootbox.confirm({
            size: 'small',
            message: message,
            callback: function(confirmed){
                if(confirmed) {
                    window.location.href = url;
                }
            }
        });
    }); 


    /**
     * Show or hide the candidate experience end date
     */
    $('#is_current').on('change', function(e) {

        var checked   = $(this).is(':checked');
        var container = $('.experience-time');

        if (checked == true) {
            container.addClass('without-end');
        } else {
            container.removeClass('without-end');
        }

    });


    /**
     * Manipulates the region list visualization
     */
    $('#form-ad').find('#country_id').on('change', regionListHandler);
    
    function regionListHandler()
    {
        var $country     = $('#country_id');
        var $regionGroup = $('form').find('.regions-group');

        var id = $country.val();

        if (id != pt_code) {
            $regionGroup.addClass('disable');
        } else {
            $regionGroup.removeClass('disable');
        }
    }


    /**
     * Show or hide elements if an external ad reply link or reply email is present
     */
    $('#form-ad').find('#reply_link').on('change keypress keydown keyup', adReplyHandler);
    $('#form-ad').find('#reply_email').on('change keypress keydown keyup', adReplyHandler);
    
    function adReplyHandler()
    {
        var $emailControl      = $('#reply_email');
        var $alertControl      = $('#alert_candidature');
        var $alertEmail        = $('#alert_email');
        var $emailGroup        = $('#group_reply_email');
        var $linkControl       = $('#reply_link');
        var $linkGroup         = $('#group_reply_link');
        var $targets           = $('.without-reply-link');

        if ($emailControl.val() || $linkControl.val()) {
            $targets.addClass('hide');
            $alertControl.prop('checked', false);
        } else {
            $targets.removeClass('hide');
        }

        if ($linkControl.val()) {
            $emailGroup.addClass('hide');
            $alertEmail.val('');
            $alertControl.prop('checked', false);
        } else {
            $emailGroup.removeClass('hide');
        }

        if ($emailControl.val()) {
            $linkGroup.addClass('hide');
            $alertControl.prop('checked', false);
        } else {
            $linkGroup.removeClass('hide');
        }

        adAlertEmailHandler();
    }


    /**
     * Show or hide elements if an alert email is asked
     */
    $('#form-ad').find('#alert_candidature').on('change', adAlertEmailHandler);

    function adAlertEmailHandler()
    {
        var $alertControl = $('#alert_candidature');
        var checked       = $alertControl.is(':checked');
        var $targets      = $('.without-alert');

        if (checked == true) {
            $targets.removeClass('hide');
        } else {
            $targets.addClass('hide');
        }
    }

    /**
     * Prevent enter from submitting form
     */
    $('.prevent-enter').on('keypress keydown keyup', function(e) {
        if (e.which == 13 && !$(e.target).is('textarea')) {
            e.preventDefault();
            return false;
        }
    });


    /*
    |--------------------------------------------------------------------------
    | FUNCTIONS
    |--------------------------------------------------------------------------
    */

    function getParameterByName(name) 
    {
        name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
        var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
            results = regex.exec(location.search);
        return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
    }


    function fillChildJobs(id)
    {
        var resource        = '/ads/filter/child/ajax';
        var parent_job      = id;
        var child_job       = '#child_job';   

        var response;

        $(child_job).find('option').remove();

        $.get(resource,
            {
                parent_job : parent_job
            },
            function(data)
            {
                $.each(data, function(index, value)
                {
                    if (index != "") {
                        $(child_job).append($("<option />").val(index).text(value));
                    } else {
                        $(child_job).prepend($("<option />").val(index).text(value));
                    }

                });

                $(child_job).val(getParameterByName('child_job'));
            }
        );
    }


    // function equalizeWithMaxHeight(selector)
    // {
    //     var highestBox = 0;

    //     // Select and loop the elements you want to equalise
    //     $(selector).each(function(){
    //         // If this box is higher than the cached highest then store it
    //         if($(this).outerHeight(true) > highestBox) {
    //             highestBox = $(this).outerHeight(true) + 30;
    //         }
    //         console.log(highestBox);
    //     });  

    //     // Set the height of all those children to whichever was highest 
    //     $(selector).height(highestBox);        
    // }


    /*
    |--------------------------------------------------------------------------
    | DEFAULTS
    |--------------------------------------------------------------------------
    */

    bootbox.setDefaults({
        /**
        * @optional String
        * @default: en
        * which locale settings to use to translate the three
        * standard button labels: OK, CONFIRM, CANCEL
        */
        locale: locale,

        /**
        * @optional Boolean
        * @default: true
        * whether the dialog should be shown immediately
        */
        show: true,

        /**
        * @optional Boolean
        * @default: true
        * whether the dialog should be have a backdrop or not
        */
        backdrop: true,

        /**
        * @optional Boolean
        * @default: true
        * show a close button
        */
        closeButton: false,

        /**
        * @optional Boolean
        * @default: true
        * animate the dialog in and out (not supported in < IE 10)
        */
        animate: true,

    });

});
